<template>
  <div>
    <div
      class="btn btn-icon btn-light btn-hover-primary mr-3"
      v-on:click="refresh"
      v-b-tooltip.hover="'Refresh'"
    >
      <span class="svg-icon svg-icon-2x svg-icon-primary">
        <inline-svg src="/media/svg/icons/Media/Repeat.svg" />
      </span>
    </div>

    <b-button
      v-if="routeType === 'active'"
      variant="light-primary"
      class="font-weight-bolder"
      v-b-toggle.employee-list-sidebar
    >
      {{ $t("EMPLOYEE.BUTTON") }}
    </b-button>

    <b-sidebar
      ref="employee-list-sidebar-ref"
      id="employee-list-sidebar"
      backdrop
      right
      lazy
      :title="$t('EMPLOYEESGENERAL.MESSAGE.TITLE_STAFF')"
      sidebar-class="offcanvas"
      header-class="offcanvas-header d-flex mt-5 ml-5 mr-5"
      bg-variant="white"
    >
      <template v-slot:header-close>
        <button
          class="btn btn-sm btn-icon btn-light-secondary btn-hover-danger"
        >
          <i class="ki ki-close icon-xs"></i>
        </button>
      </template>
      <div class="px-10 mt-5">
        <StaffForm
          v-slot:default="{}"
          :title="$t('USERFORM.USERDETAILS')"
          v-on:success="closeSideBar"
        ></StaffForm>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import StaffForm from "@/modules/school/components/staff/list/StaffPageCreateForm";

export default {
  name: "StaffPageControl",
  components: { StaffForm },
  methods: {
    closeSideBar() {
      const sideBar = this.$refs["employee-list-sidebar-ref"];
      sideBar.hide();
      this.reload = !this.reload;
    },
    refresh() {
      const type = this.getRouteType();

      switch (type) {
        case "active":
          this.$store.dispatch("fetchStaffList");
          break;
        case "archived":
          this.$store.dispatch("fetchStaffArchivedList");
          break;
        default:
          break;
      }
    },

    /**
     *
     * @returns {string}
     */
    getRouteType() {
      const route = this.$route.name;
      console.log("Current route :", route);

      let type = null;
      if (route === "staffActiveList") {
        console.log("Route is Staff list.");
        type = "active";
      } else {
        type = "archived";
      }
      return type;
    },
  },
  computed: {
    routeType() {
      return this.getRouteType();
    },
  },
};
</script>

<style scoped></style>
