<template>
  <div>
    <StaffActivePageTableControl v-if="type === 1" />
    <StaffArchivedPageTableControl v-if="type === 2" />
  </div>
</template>

<script>
import StaffActivePageTableControl from "@/modules/school/components/staff/filter/StaffActivePageTableControl";
import StaffArchivedPageTableControl from "@/modules/school/components/staff/filter/StaffArchivedPageTableControl";
export default {
  name: "StaffPageTableControl",
  components: { StaffArchivedPageTableControl, StaffActivePageTableControl },
  data() {
    return {
      type: null,
    };
  },
  mounted() {
    this.checkRoute(this.$route.name);
  },
  methods: {
    checkRoute(name) {
      switch (name) {
        case "staffActiveList":
          this.type = 1;
          break;
        case "staffArchivedList":
          this.type = 2;
          break;
        default:
          this.type = 3;
          break;
      }
    },
  },
  watch: {
    $route(to, from) {
      console.log(to, from);

      this.checkRoute(to.name);
    },
  },
};
</script>

<style scoped></style>
