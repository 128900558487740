<template>
  <div class="d-flex flex-column">
    <h4 class="font-weight-bold my-5">{{ title }}</h4>

    <!-- begin:Form   -->
    <b-form class="form" v-on:submit.prevent="save">
      <!--  begin:First Name   -->
      <b-form-group :label="$t('EMPLOYEESGENERAL.MESSAGE.FIRSTNAME')">
        <b-form-input
          id="employee-first-name-input"
          class="form-control h-auto"
          v-model="$v.form.firstName.$model"
          :state="validateState('firstName')"
          :placeholder="$t('USERFORM.NAME')"
        >
        </b-form-input>
      </b-form-group>
      <!--  end:First Name   -->

      <!--  begin:Last Name    -->
      <b-form-group :label="$t('EMPLOYEESGENERAL.MESSAGE.LASTNAME')">
        <b-form-input
          id="employee-email-input"
          class="form-control h-auto"
          v-model="$v.form.lastName.$model"
          :state="validateState('lastName')"
          :placeholder="$t('USERFORM.LASTNAME')"
        >
        </b-form-input>
      </b-form-group>
      <!--  end:Last Name    -->

      <!--  begin:Email    -->
      <b-form-group :label="$t('EMPLOYEESGENERAL.MESSAGE.EMAIL')">
        <b-form-input
          id="employee-email-input"
          class="form-control h-auto"
          v-model="$v.form.email.$model"
          :state="validateState('email')"
          :placeholder="$t('USERFORM.EMAIL')"
        >
        </b-form-input>
      </b-form-group>
      <!--  end:Email    -->

      <!-- begin:Unique Code -->
      <b-form-group :label="$t('EMPLOYEESGENERAL.MESSAGE.UNIQUE')">
        <b-form-input
          id="employee-unique-code-input"
          class="form-control h-auto"
          v-model="$v.form.uniqueCode.$model"
          :state="validateState('uniqueCode')"
          :placeholder="$t('USERFORM.UNIQUECODE')"
          debounce="500"
        >
        </b-form-input>
        <b-form-invalid-feedback v-if="!$v.form.uniqueCode.$params.codeStatus">
          Code Status cannot be used. Already taken by other user
        </b-form-invalid-feedback>
      </b-form-group>
      <!--  end:Unique    -->

      <!--begin::Action-->
      <div class="form-group d-flex flex-column">
        <button
          type="submit"
          ref="submit"
          class="btn btn-primary font-weight-bolder"
        >
          {{ $t("USERS.DETAILS.SUBMIT") }}
        </button>
      </div>
      <!--end::Action-->
    </b-form>
    <!-- end:Form   -->
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  minLength,
  maxLength,
  email,
  required,
} from "vuelidate/lib/validators";

export default {
  name: "StaffForm",
  mixins: [validationMixin],
  props: {
    title: {
      type: String,
      default: "Staff Form",
    },
  },

  data() {
    return {
      form: {
        firstName: "",
        lastName: "",
        email: "",
        uniqueCode: "",
        uniqueCodeConfirm: "",
      },
      isBusy: false,
      codeStatus: false,
    };
  },
  validations: {
    form: {
      firstName: {
        required,
        minLength: minLength(2),
      },
      lastName: {
        required,
        minLength: minLength(2),
      },
      email: {
        required,
        email,
      },
      uniqueCode: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(20),
        codeExists: (value, vm) => {
          console.log(value, vm);
          console.log("Code Status", vm);
          return !vm.uniqueCodeConfirm;
        },
      },
      uniqueCodeConfirm: {
        required,
      },
    },
  },

  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    save() {
      console.log("Submitting staff form.");
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        console.log("Form in complete");
        return;
      }

      const submitButton = this.$refs["submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      const firstName = this.form.firstName;
      const lastName = this.form.lastName;
      const email = this.form.email;
      const uniqueCode = this.form.uniqueCode;

      this.isBusy = true;
      this.$store
        .dispatch("createNewStaff", {
          first_name: firstName,
          last_name: lastName,
          email: email,
          code: uniqueCode,
        })
        .then((response) => {
          this.$emit("success", response.data);
        })
        .catch((error) => {
          this.$bvModal.msgBoxOk("Error!!!", {
            title: "Failed to register",
            centered: true,
          });
          this.$emit("error", error);
          return null;
        })
        .finally(() => {
          this.$store.dispatch("fetchStaffList");
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        });
    },
  },
  watch: {
    form: {
      handler(form) {
        console.log("Watching", form);

        // Server-side validation
        const code = form.uniqueCode;
        if (code) {
          const payload = {
            code: code,
          };

          console.log("Kat Sini");
          this.$store
            .dispatch("checkExistingStaffCode", payload)
            .then((response) => {
              console.log(response);
              this.form.uniqueCodeConfirm = response.data.status === "true";
              console.log("Confirm", this.form.uniqueCodeConfirm);
              if (response.data.status === "true") {
                console.log("Data Exist");
              }
            });
        }
      },
      deep: true,
    },

    isBusy(value) {
      console.log("Monitoring Busy", value);
    },
  },
};
</script>

<style scoped></style>
