<template>
  <div>
    <StaffPageTableControl class="mb-5" />
    <AppPageCard>
      <template #card-title>
        {{ $t("STAFF.HEADER") }}
      </template>
      <template #card-toolbar>
        <StaffPageControl></StaffPageControl>
      </template>
      <b-nav pills>
        <b-nav-item to="active" exact exact-active-class="active"
          >{{ "List" }}
        </b-nav-item>
        <b-nav-item to="archived" exact exact-active-class="active"
          >{{ "Archived" }}
        </b-nav-item>
      </b-nav>

      <div class="mt-5">
        <router-view></router-view>
      </div>
    </AppPageCard>
  </div>
</template>

<script>
import AppPageCard from "@/modules/core/components/shared/card/AppPageCard";
import StaffPageControl from "@/modules/school/components/staff/StaffPageControl";
import StaffPageTableControl from "@/modules/school/components/staff/filter/StaffPageTableControl";
export default {
  name: "SchoolStaffPage",
  components: { StaffPageTableControl, StaffPageControl, AppPageCard },
};
</script>

<style scoped></style>
