<template>
  <AppTableControl
    no-filter
    no-export
    @query-debounced="searchName"
    @search-clicked="fetch"
  ></AppTableControl>
</template>

<script>
import AppTableControl from "@/modules/core/components/shared/filter/AppTableControl";
export default {
  name: "StaffArchivedPageTableControl",
  components: { AppTableControl },
  beforeDestroy() {
    this.$store.dispatch("changeStaffArchivedListBaseFilters", {
      all: null,
    });
  },
  methods: {
    fetch() {
      this.$store.dispatch("fetchStaffArchivedList");
    },
    /**
     * Search
     * @param name
     */
    searchName(name) {
      console.log("Name", name);

      this.$store.dispatch("changeStaffArchivedListBaseFilters", {
        all: name,
      });

      this.fetch();
    },
  },
};
</script>

<style scoped></style>
